import config from '../config';
import { uncheckedIndexAccess_UNSAFE } from '../utils/uncheckedIndexAccess_UNSAFE';

// String values 'enabled' | 'disabled' are converted to a boolean
// Array values use the first element as the default, and display as a select list in the debug-tools
// Numeric values always return a numeric value by parsing the query value
// Everything is type-safe

const Features = {
  'featured-game': '',

  'music-player': 'enabled',
  'music-player-playlist': 'PLSjdXq1fkgoNqZZ1DxSJu2X-SbbbknWqQ',
  'music-player-test-track': 'disabled',
  'crowd-view': 'enabled',
  'crowd-view-anim-static-duration-ms': 10000,

  // What frame profile to request when rendering the crowd view
  'crowd-view-cf-profile': [
    'wh100x100fps8',
    'wh100x100fps6',
    'wh100x100fps4',

    'wh75x75fps8',
    'wh75x75fps6',
    'wh75x75fps4',

    'wh50x50fps8',
    'wh50x50fps6',
    'wh50x50fps4',

    'wh36x36fps8',
    'wh36x36fps6',
    'wh36x36fps4',

    'wh100x100fps8strip16',
    'wh75x75fps8strip16',
    'wh50x50fps8strip16',
    'wh36x36fps8strip16',

    'wh100x100fps8strip1',
    'wh75x75fps8strip1',
    'wh50x50fps8strip1',
    'wh36x36fps8strip1',
  ],

  // What frame profile to request when rendering the mini version
  'mini-cf-profile': [
    'wh36x36fps4',
    'wh36x36fps8',
    'wh36x36fps6',

    'wh36x36fps8strip16',
    'wh36x36fps8strip1',
  ],

  // Add fake participants to the teams tab and crowd view
  'fake-participants': 'disabled',

  'crowd-frames-service': ['real', 'dummy'],
  'crowd-frames-avatar-rendering': 'enabled',
  'crowd-frames-infrequent-rendering': 'disabled',
  'crowd-frames-extractor': 'enabled',
  'crowd-frames-extractor-will-read-frequently': 'disabled',
  'crowd-frames-registration': 'enabled',
  'crowd-frames-async-image-decode': 'enabled',

  //
  // BEGIN CROWD FRAMES EXTRACTOR PROFILES
  //

  'crowd-frames-send-slot-0': [
    'wh100x100fps8',
    'wh100x100fps6',
    'wh100x100fps4',

    'wh75x75fps8',
    'wh75x75fps6',
    'wh75x75fps4',

    'wh50x50fps8',
    'wh50x50fps6',
    'wh50x50fps4',

    'wh36x36fps8',
    'wh36x36fps6',
    'wh36x36fps4',

    'wh100x100fps8strip16',
    'wh75x75fps8strip16',
    'wh50x50fps8strip16',
    'wh36x36fps8strip16',

    'wh100x100fps8strip1',
    'wh75x75fps8strip1',
    'wh50x50fps8strip1',
    'wh36x36fps8strip1',

    'all-strip16-profiles',
    'all-strip8-profiles',
    'all-strip6-profiles',
    'all-strip4-profiles',
    'all-strip1-profiles',
  ],

  'crowd-frames-send-slot-1': [
    'wh36x36fps4',
    'wh36x36fps8',
    'wh36x36fps6',

    'wh100x100fps8',
    'wh100x100fps6',
    'wh100x100fps4',

    'wh75x75fps8',
    'wh75x75fps6',
    'wh75x75fps4',

    'wh50x50fps8',
    'wh50x50fps6',
    'wh50x50fps4',

    'wh100x100fps8strip16',
    'wh75x75fps8strip16',
    'wh50x50fps8strip16',
    'wh36x36fps8strip16',

    'wh100x100fps8strip1',
    'wh75x75fps8strip1',
    'wh50x50fps8strip1',
    'wh36x36fps8strip1',

    'all-strip16-profiles',
    'all-strip8-profiles',
    'all-strip6-profiles',
    'all-strip4-profiles',
    'all-strip1-profiles',
  ],

  'crowd-frames-send-slot-2': [
    'wh100x100fps8',
    'wh100x100fps6',
    'wh100x100fps4',

    'wh75x75fps8',
    'wh75x75fps6',
    'wh75x75fps4',

    'wh50x50fps8',
    'wh50x50fps6',
    'wh50x50fps4',

    'wh36x36fps8',
    'wh36x36fps6',
    'wh36x36fps4',

    'wh100x100fps8strip16',
    'wh75x75fps8strip16',
    'wh50x50fps8strip16',
    'wh36x36fps8strip16',

    'wh100x100fps8strip1',
    'wh75x75fps8strip1',
    'wh50x50fps8strip1',
    'wh36x36fps8strip1',

    'all-strip16-profiles',
    'all-strip8-profiles',
    'all-strip6-profiles',
    'all-strip4-profiles',
    'all-strip1-profiles',
  ],

  'crowd-frames-send-slot-3': [
    'wh36x36fps4',
    'wh36x36fps8',
    'wh36x36fps6',

    'wh100x100fps8',
    'wh100x100fps6',
    'wh100x100fps4',

    'wh75x75fps8',
    'wh75x75fps6',
    'wh75x75fps4',

    'wh50x50fps8',
    'wh50x50fps6',
    'wh50x50fps4',

    'wh100x100fps8strip16',
    'wh75x75fps8strip16',
    'wh50x50fps8strip16',
    'wh36x36fps8strip16',

    'wh100x100fps8strip1',
    'wh75x75fps8strip1',
    'wh50x50fps8strip1',
    'wh36x36fps8strip1',

    'all-strip16-profiles',
    'all-strip8-profiles',
    'all-strip6-profiles',
    'all-strip4-profiles',
    'all-strip1-profiles',
  ],

  //
  // END CROWD FRAMES EXTRACTOR PROFILES
  //

  // Cohost
  cohost: 'disabled',
  'cohost-video-mixer': 'disabled',
  'cohost-fade-ms': 250,
  'cohost-team-randomization': ['instant', 'animated'],
  'cohost-panel': ['disabled', 'minimized', 'open', 'maximized'],
  'cohost-panel-games-overview': 'enabled',

  // Host

  'host-audio-bus-processing': 'enabled',
  'host-video-profile': ['720p', '1080p', '480p', '360p', '240p'],
  'host-stream-quality-auto-recovery': 'enabled',
  'host-stream-low-quality-profile': ['120p', '240p', '360p'],
  'host-stream-view': 'enabled',
  'host-studio-version': ['2', '1'],

  // Sentiment

  'sentiment-detector': ['stream', 'always', 'disabled'],
  'sentiment-detector-worker': 'enabled',
  'sentiment-detector-interval-ms': 2000,
  'sentiment-recorder': 'enabled',
  'sentiment-backend': ['wasm', 'webgl'],
  'sentiment-confidence-threshold': config.sentiment.confidenceThreshold,
  'sentiment-recording-profile': [
    'wh480x360fps8',
    'wh640x480fps8',
    'wh320x240fps8',
  ],
  'sentiment-buffer-will-read-frequently': 'disabled',
  'sentiment-buffer': ['auto', 'image-bitmap', 'canvas'],

  'emoji-board': 'enabled',
  'emoji-board-emoji-duration-ms': 2500,
  'emoji-board-emoji-fadeout-ms': 500,
  'emoji-board-emoji-speed-x': 0.1,
  'emoji-board-emoji-grouping-ms': 2000,

  // Audio
  'use-automatic-gain-control': 'enabled',
  'use-automatic-noise-suppression': 'enabled',
  'use-hack-echo-cancellation': 'disabled',
  'ensure-unlocked-audio': 'enabled',
  'stage-stream-microphone-meter': 'enabled',
  'host-mixer-target-min-dbfs': -25,
  'host-mixer-target-max-dbfs': -10,
  'host-mixer-min-dbfs': -100,
  'host-mixer-alignment-dbfs': -18,
  'host-mixer-warn-dbfs': -3,
  'host-mixer-max-dbfs': 0,

  // Randomizer
  'team-randomizer': 'enabled',
  'team-randomizer-show-animation': 'enabled',
  'team-randomizer-force-use-current-team': castBooleanFeatureValue(
    config.team.randomizer.defaultToCurrentTeam
  ),
  'team-raondomizer-min-players': 2,

  // Game
  'game-play-go-animation': 'enabled',
  'game-play-media': ['enabled', 'disabled', 'one-stream'],
  'game-play-media-debug-layout': 'disabled',
  'game-play-video-profile': ['720p', '720p_1', '540p', '480p', '360p', '240p'],
  'game-play-video-proxy-debug': 'disabled',
  'game-play-video-loop-method': ['auto', 'timer', 'raf'],
  'game-play-video-loop-use-worker': ['auto', 'enabled', 'disabled'],
  'game-play-video-test-playback': 'disabled',
  'game-play-video-proxy-use-video-placeholder': 'enabled',
  'game-play-video-use-local-thumbnail': 'enabled',
  'game-play-background-media-scale': 1,
  'game-play-scoreboard-animation': 'enabled',
  'game-on-demand-tick-use-worker': 'enabled',
  'game-on-demand-tick-loop-method': ['timer-accumulated', 'raf-accumulated'],
  'game-on-demand-video-mixer-loop-method': [
    'timer-accumulated',
    'raf-accumulated',
  ],
  'game-on-demand-video-mixer-loop-use-worker': 'enabled',
  'game-test-switch-videos': 'disabled',
  'game-on-demand-video-transition-duration-ms': 0,
  'game-on-demand-audio-transition-duration-ms': 0,
  'game-on-demand-video-stage-background-as-needed': 'enabled',
  'game-use-raw-format': 'disabled',
  'game-on-demand-skip-checklist': castBooleanFeatureValue(
    config.misc.skipOnDGameChecklist
  ),
  'game-on-demand-host-video-profile': [
    '720p_1',
    '720p',
    '540p',
    '480p',
    '360p',
    '240p',
  ],
  'game-on-demand-desync-correction': 'enabled',
  'game-on-demand-intro': 'enabled',
  'game-on-demand-host-first-freeze-frame-duration': 500,
  'game-on-demand-host-final-freeze-frame-duration': 500,
  'game-on-demand-pause-on-end-block-max-wait-sec': 60,
  'game-on-demand-fractional-shift-actions': 'enabled',
  'game-on-demand-force-v31-playback': 'disabled',
  'game-on-demand-v31-stage': ['stage-014', 'stage-002'],
  'game-on-demand-use-play-history': ['enabled', 'disabled'],
  'game-on-demand-v31-boot-sleep-ms': 500,
  'game-on-demand-skip-timer-control': 'disabled',
  'game-on-demand-card-delay-duration': 0,
  'game-on-demand-play-test': ['disabled', 'enabled', 'float'],
  'game-on-demand-inject-marketing-blocks': 'enabled',
  'game-on-demand-onstage-volume-boost': 100,
  'game-on-demand-stuck-game-button': 'enabled',

  // Game Editing
  'block-editor-voiceover': 'enabled',

  // Game Recording
  'block-recorder-count-in-ms': ['disabled', '5000'],
  'block-recorder-auto-advance': ['continue', 'wait'],

  // WebRTC
  'stream-codec': ['vp8', 'h264', 'adaptive', 'vp9'],
  'stream-cloud-proxy': 'disabled',
  'stream-get-user-media-debug': 'disabled',
  'stream-use-dummy': 'disabled',
  'stream-use-dual-stream': 'enabled',
  'stream-join-fault-injection-times': 0,
  'stream-join-max-retries': 120,
  'stream-join-retry-interval-ms': 1000,
  'stream-join-timeout-ms': 15000,

  // Team Relay
  'team-relay-auto-progress-timeout': 4000,
  'team-relay-auto-progress-if-player-disconnected': 'enabled',
  'team-relay-wrong-turn-penalty': 'enabled',
  'team-relay-extended-node-min-length': 1,
  'team-relay-debug-score': 'disabled',

  // Spotlight Block
  'spotlight-block-welcome-message': 'enabled',
  'spotlight-block-overlay-media': 'enabled',
  'spotlight-block-players': 'enabled',
  'spotlight-block-background-media': 'enabled',

  // Shows hidden blocks in the admin tool
  'show-hidden-blocks': 'disabled',

  // Team
  'leave-team': 'disabled',
  'super-max-team-size': 'disabled',
  'team-view': 'enabled',
  'team-view-remote-video-streams': 'enabled',
  'team-panel-zero-state': 'enabled',
  'team-panel-persistent-create-team': 'disabled',
  'team-audio-denoiser': 'disabled',

  // Cloud Hosting
  'cloud-hosting': castBooleanFeatureValue(config.cloudHosting.enabled),
  'cloud-hosting-launch-timeout': 15000,
  'cloud-hosting-controller-heartbeat': 5000,
  'cloud-hosting-health-check-interval': 5000,
  'cloud-hosting-coordinator-call-timeout': 10000,
  'cloud-hosting-coordinator-check-interval': 200,
  'cloud-hosting-mock-api-call': 'disabled',
  'cloud-hosting-feature-overrides': '', // comma-separated feature=value pairs
  'cloud-hosting-adv-admin': 'disabled',
  'cloud-hosting-group': ['default', 'muted'],
  'cloud-hosting-health-check-notify-cooldown': 60000,

  // Firebase
  'firebase-latency-measure': 'enabled',
  'firebase-latency-meter': 'enabled', // the visual component
  'firebase-latency-log-emit-ms': 10000,
  'firebase-latency-smoothing': 9000, // max is 10000, equivalent to 1.0000
  'firebase-latency-measure-ms': 1000,
  'firebase-latency-window-size': 20,
  'firebase-latency-good-ms': 100,
  'firebase-latency-poor-ms': 150,
  'firebase-auto-recovery-interval-ms': 1000,
  'firebase-auto-recovery-max-attempts': 10,

  // Chat
  chat: 'enabled',
  'chat-private-channel': 'disabled',
  'chat-notifs-user-entered': 'enabled',
  'chat-notifs-user-tips': 'enabled',

  // Memory Match
  'memory-match-debug': 'disabled',
  'memory-match-num-of-pairs': 0,
  'memory-match-cancel-shake-when-unreveal-wrong-match': 'enabled',

  // Lite Mode
  'lite-mode-notif-trigger-detractor-score': 45,

  // Small Screen Size Hack
  'small-viewport-hack-max-width-px': 0,
  'small-viewport-hack-max-height-px': 0,
  'small-viewport-hack-font-size-px': 16,

  // Townhall
  townhall: 'enabled',
  'townhall-force-use-header-right-widget': 'disabled',
  'townhall-ond-auto-switch-ideal-countdown-sec': 3,
  'townhall-video-strategy-active-speaker-threshold': 5,
  'townhall-audio-strategy-mic-enabled-threshold': 45,
  'townhall-keep-streams-for-most-recent-speakers': 'enabled',
  'townhall-ond-auto-activate-for-v3-game': 'enabled',
  'townhall-live-host-toggle': 'disabled',
  'townhall-live-large-group-threshold': 125,
  'townhall-live-large-group-reset-after-game-secs': 900,
  'townhall-video-strategy-force-crowd-frames': [
    'disabled',
    'enabled',
    'active-stream',
  ],
  'townhall-ond-auto-mute-threshold': 45,
  'townhall-force-mode': ['auto', 'team', 'crowd'],
  'townhall-height-pct': 20,
  'townhall-cohost-pct': 20,

  // Program
  'show-inactive-programs': 'disabled',
  'celebration-upcoming-days': 30,
  'celebration-send-time-recent': 'disabled',
  'create-new-program': 'disabled',
  'program-force-install': 'disabled',
  'programs-analytics': 'enabled',

  // Memories
  memories: 'enabled',
  'memories-group-photo': 'enabled',
  'memories-use-participant-team-id': 'enabled',

  // Drawing
  'drawing-undo': 'enabled',
  'drawing-collaborative-experiment': 'disabled',
  'drawing-collaborative-cursor': 'disabled',
  'drawing-force-team-vote': 'disabled',

  // Puzzle Block
  'puzzle-gif-sync-throttle-ms': 5000,
  'puzzle-gif-sync-cache-buster': 'disabled',

  // Hidden Picture
  'hidden-picture-show-hotspots': 'disabled',

  // Instruction
  'instruction-block-v2': 'disabled',

  // Jeopardy
  'jeopardy-no-category-introduction': 'disabled',

  // Head To Head
  'head-to-head-player-voting': 'disabled',

  // promotion
  'promotion-debug': 'disabled',

  // Game Pack
  gpv2: 'enabled',
  'gpv2-fake-played-history': 0,
  'game-pack-hover-preview': 'enabled',
  'game-pack-hover-delayed-ms': 150,
  'game-pack-hover-transition-ms': 300,
  'game-pack-hover-expand-duration-ms': 150,
  'game-pack-hover-expand-delay-ms': 300,
  'gp-library-play-again-row': 'disabled',
  'gpv2-search-filters': 'enabled',
  'game-pack-repeat-purchase': 'disabled',
  'game-pack-card-price': 'disabled',
  'game-pack-card-free-badge': 'disabled',
  'game-pack-ugc': 'enabled',
  'game-pack-ugc-ai': 'enabled',

  // Block Vote
  'block-vote': 'enabled',
  'block-revotable': 'disabled',
  'block-vote-gpv1': 'enabled',
  'block-vote-custom-reason': 'enabled',

  // Event
  'event-attendees-select-slack': 'enabled',
  'event-attendees-max-count': 125,

  // Typeform
  'typeform-show-interval-sec': 172800,
  'typeform-ignore-answered': 'disabled',

  'ui-animations': 'enabled',

  // Mic
  'mic-volume-meter': 'enabled',
  'mic-mw-volume-threshold': 30,
  'mic-mw-hide-after-ms': 3000,
  'mic-mw-audio-off-cooldown-ms': 1000,
  'mic-mw': ['ond', 'live', 'enabled', 'disabled'],

  // Lobby
  'lobby-event-preview': 'disabled',
  'lobby-others-playing': 'enabled',
  'lobby-others-playing-ticker-speed-px': 30,
  'lobby-switch-game': 'enabled',

  // mark as away
  'mark-as-away': 'enabled',

  // venue capacity check
  'venue-capacity-check': 'enabled',

  // AI Grade
  'question-block-ai-grade': 'enabled',
  'question-block-ai-grade-temperature': 0,
  'round-robin-block-ai-grade': ['standard', 'disabled', 'enabled'],
  'round-robin-block-ai-grade-temperature': 0,

  // WebRTC Connection Test
  'connection-test-timeout': 25000,
  'connection-test-in-venue-timeout-ms': 60000,
  'connection-test-in-venue': 2, // zero means disabled, positive number means max attempts
  'connection-test-in-venue-wait-ms': 60000,

  // team-intro
  'team-intro-extra-delay-ms': 0,
  'team-intro-sparkle-animation': 'enabled',

  // zoom
  'zoom-mock-env': 'disabled', // simulate the zoom environment, so we don't need zoom to test
  'zoom-mock-meeting-id': 'mock-meeting-id',
  'zoom-mock-running-context': ['inMeeting', 'inMainClient', 'inCollaborate'],

  // Misc
  'debug-tools': ['disabled', 'enabled', 'float', 'collapsed'],
  'debug-tools-rstats': 'enabled',
  'debug-tools-rstats-user-timing': ['disabled', 'enabled'],
  'verbose-local-logging': 'disabled',
  'debug-canvas': 'disabled',
  h2h: 'enabled',
  'gain-points-anim-debug-draw': 'disabled',
  'broadcast-best-effort-max-users': 20,
  'async-worker-logging': ['enabled', 'disabled'],
  'logging-transport': ['fetch', 'axios'],
  'ferris-wheel': 'disabled',
  'game-mode-selection': 'enabled',
  'framerate-measure': 'enabled',
  // note: the default value is the first item in the array. the mode called 'default' is probably better called 'venue'
  // since it uses the venue's configuration for enabling/disabling guest-users.
  'guest-users': [
    'venue-register',
    'default',
    'enabled',
    'disabled',
    'conversion',
    'register',
  ],
  sentry: 'enabled',
  'message-template-copy-debug': 'disabled',
  'chromakey-processor-version': ['v2', 'v1', 'jf'],
  'clock-syncing': 'enabled',
  'resize-observer': ['native', 'polyfill'],
  'global-pairs-leaderboard': 'enabled',
  'offboarding-ignore-reset': 'enabled',
  'toolkit-tz': 'America/New_York',
  'live-event-trailers-delayed-ms': 800,
  'live-event-trailers-transition-duration-ms': 200,
  maintenance: 'disabled',
  precache: ['production', 'any'],
  'create-lp-channel-after-connect-to-slack': 'disabled',
  'venue-settings-guest-control': 'disabled',
  psv: ['v1', 'v2'],
  'participant-flush': ['queued', 'immediate'],
  'redux-auto-batching': 'enabled',
  'analytics-summary-data-points': 12,
  'analytics-still-joy-capture': 'enabled',
  'price-as-monthly': 'disabled',
  'analytics-export': 'enabled',
  'analytics-slides-download': 'disabled',
  safari: 'enabled',
  'settings-voice-over-language': 'enabled',
  'virtual-background': 'disabled',
  'skip-device-check-in-session': 'enabled',
  'show-legacy-vo-editors': 'disabled',
  'super-admin': 'disabled',
  'skip-vip-blocks': 'disabled',
  'show-block-logic': 'disabled',
  'otp-checkout': 'disabled',
} as const;

type FeaturesT = typeof Features;

type BooleanUrlValue = 'enabled' | 'disabled';

// These ensure that at compile time the various get* functions only accept keys
// they know how to parse and have compatible initial value types.
type FeaturesTBooleans = {
  [K in FilteredKeys<FeaturesT, BooleanUrlValue>]: FeaturesT[K];
};
type FeaturesTNumbers = {
  [K in FilteredKeys<FeaturesT, number>]: FeaturesT[K];
};
type FeaturesTArrays = {
  [K in FilteredKeys<
    FeaturesT,
    ArrayLike<string>
  > as FeaturesT[K] extends string ? never : K]: FeaturesT[K];
};
type FeaturesTStrings = {
  // the boolean features are the only other values that can masquerade as
  // `string`, so exclude them using `never`
  [K in FilteredKeys<FeaturesT, string> as K extends keyof FeaturesTBooleans
    ? never
    : K]: FeaturesT[K];
};

/** exported for test/mock helpers ONLY, do not use directly otherwise. */
export type FeatureQueryParamBooleans = FeaturesTBooleans;
/** exported for test/mock helpers ONLY, do not use directly otherwise. */
export type FeatureQueryParamNumbers = FeaturesTNumbers;
/** exported for test/mock helpers ONLY, do not use directly otherwise. */
export type FeatureQueryParamArrays = FeaturesTArrays;
/** exported for test/mock helpers ONLY, do not use directly otherwise. */
export type FeatureQueryParamStrings = FeaturesTStrings;

function getSearchString() {
  return typeof document !== 'undefined' && typeof window !== 'undefined'
    ? window.location.search
    : '';
}

export function getFeatureQueryParam(
  feature: keyof FeaturesTBooleans
): boolean {
  const params = new URLSearchParams(getSearchString());
  const param = params.get(feature);
  const entry = Features[feature];
  if (!isBooleanFeature(feature))
    throw new Error(`Feature ${feature} is not a boolean`);
  if (param) return param === 'enabled';
  else return entry === 'enabled';
}

export function getFeatureQueryParamNumber(
  feature: keyof FeaturesTNumbers,
  float?: boolean
): number {
  const params = new URLSearchParams(getSearchString());
  const param = params.get(feature);
  const entry = Features[feature];
  if (!isNumberFeature(feature)) {
    throw new Error(`Feature ${feature} is not numeric`);
  } else if (param) {
    const parsed = parseNumberParam(param, float);
    if (parsed === null) return entry; // default
    return parsed;
  } else {
    return entry;
  }
}

export function getFeatureQueryParamArray<K extends keyof FeaturesTArrays>(
  feature: K
): FeaturesTArrays[K][number] {
  const params = new URLSearchParams(getSearchString());
  const param = params.get(feature);
  const entry = Features[feature];
  if (!isArrayFeature(feature)) {
    throw new Error(`Feature ${feature} is not an array`);
  } else if (param) {
    const cleaned = param.trim().toLowerCase();
    const idx = (entry as readonly string[]).indexOf(cleaned);
    const found = idx > -1;
    if (!found) throw new Error(`Feature ${feature} value not found`);
    return entry[idx];
  } else {
    return entry[0];
  }
}

export function getFeatureQueryParamString<K extends keyof FeaturesTStrings>(
  feature: K
): string {
  const params = new URLSearchParams(getSearchString());
  const param = params.get(feature);
  const entry = Features[feature];
  if (!isStringFeature(feature))
    throw new Error(`Feature not defined: ${feature}`);
  return param ?? entry;
}

/**
 * Return the "raw" (unparsed, un-casted, non-defaulted) query param value for a
 * known feature. This function should be used as a last resort, for meta
 * (reflective) purposes (such as the debug tools), or only if the resulting
 * value can not be defined up-front (such as an ID).
 */
export function getRawFeatureQueryParam(
  feature: keyof typeof Features
): string | null {
  const params = new URLSearchParams(getSearchString());
  const param = params.get(feature);
  if (!(feature in Features)) return null;
  return param;
}

/**
 * Return a feature's value as an array of feature=value string. Each feature is
 * tested to be known and with a relatively valid value.
 *
 * Example:
 *
 *     'featureName1=value2,featureName2=value2' =>
 *     ['featureName1=value2', 'featureName2=value2'].
 *
 * This is mostly valuable for having confidence in passing a list of feature
 * flags to another service or client, and should be used sparingly.
 */
export function getFeatureQueryParamCommaSeparatedFeaturesString<
  K extends keyof FeaturesTStrings
>(feature: K): string[] {
  return getFeatureQueryParamString(feature)
    .split(',')
    .map((f) => {
      validateFeatureQueryParamPair(f);
      return f;
    });
}

// This is to avoid needing to import React.useMemo in this file. This keeps
// this file lean and able to be used nearly anywhere.
const memoedFeatureQueryParamCache = new Map<
  keyof FeaturesTBooleans,
  boolean
>();

export function useFeatureQueryParam(
  feature: keyof FeaturesTBooleans
): boolean {
  const cached = memoedFeatureQueryParamCache.has(feature);
  if (cached) return memoedFeatureQueryParamCache.get(feature) as boolean;

  const value = getFeatureQueryParam(feature);
  memoedFeatureQueryParamCache.set(feature, value);
  return value;
}

export function getDefaultFeatures(): typeof Features {
  return Features;
}

function isBooleanFeatureValue(val: unknown): val is BooleanUrlValue {
  return val === 'enabled' || val === 'disabled';
}

function castBooleanFeatureValue(val: unknown): BooleanUrlValue {
  if (isBooleanFeatureValue(val)) {
    return val;
  }
  throw new Error(`${val} is not a boolean`);
}

function parseNumberParam(param: unknown, float?: boolean): number | null {
  if (typeof param !== 'string') return null;
  const parsed = float ? parseFloat(param) : parseInt(param, 10);
  if (isNaN(parsed)) return null;
  return parsed;
}

function isBooleanFeature(
  feature: unknown,
  value?: unknown
): feature is keyof FeaturesTBooleans {
  const f =
    typeof feature === 'string' &&
    feature in Features &&
    isBooleanFeatureValue(uncheckedIndexAccess_UNSAFE(Features)[feature]);
  const v = typeof value === 'undefined' ? true : isBooleanFeatureValue(value);
  return f && v;
}

function isArrayFeature(
  feature: unknown,
  value?: unknown
): feature is keyof FeaturesTArrays {
  const f =
    typeof feature === 'string' &&
    feature in Features &&
    Array.isArray(uncheckedIndexAccess_UNSAFE(Features)[feature]);
  const v = typeof value === 'undefined' ? true : typeof value === 'string';
  return f && v;
}

function isStringFeature(
  feature: unknown,
  value?: unknown
): feature is keyof FeaturesTStrings {
  const f =
    typeof feature === 'string' &&
    feature in Features &&
    typeof uncheckedIndexAccess_UNSAFE(Features)[feature] === 'string';
  const v = typeof value === 'undefined' ? true : typeof value === 'string';
  return f && v;
}

function isNumberFeature(
  feature: unknown,
  value?: unknown
): feature is keyof FeaturesTNumbers {
  const f =
    typeof feature === 'string' &&
    feature in Features &&
    typeof uncheckedIndexAccess_UNSAFE(Features)[feature] === 'number';
  const v = typeof value === 'undefined' ? true : !!parseNumberParam(value);
  return f && v;
}

function validateFeatureQueryParamPair(pair: string): void {
  const params = new URLSearchParams(pair);
  for (const [feature, value] of params.entries()) {
    if (!(feature in Features))
      throw new Error(`Feature not defined: ${feature}`);

    if (isBooleanFeature(feature, value)) continue;
    if (isArrayFeature(feature, value)) continue;
    if (isStringFeature(feature, value)) continue;
    if (isNumberFeature(feature, value)) continue;

    throw new Error(`${feature} or ${value} in pair ${pair} is unknown`);
  }
}
